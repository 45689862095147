<template>
  <div class="header">
    <div class="nav1">
      <div class="tit1" v-html="headerTit"></div>
      <div class="tit2">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            admin<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">修改密码</el-dropdown-item>
            <el-dropdown-item command="2">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 上/下架 -->
    <el-dialog title="修改账号/密码" :visible.sync="sxjaddDialogVisible" width="700px" :before-close="sxjaddHandleClose">
      <div class="myAddForm">
        <el-form :model="addForm" ref="addForm" label-width="150px" class="demo-addForm">
          <el-row>
            <el-col :span="20">
              <el-form-item label="账号名称">
                <el-input size="small" placeholder="请输入账号名称" v-model="addForm.user_name"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="新密码">
                <el-input size="small" placeholder="请输入新密码" v-model="addForm.userpass"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="确认新密码">
                <el-input size="small" placeholder="请确认新密码" v-model="addForm.re_userpass"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item>
                <el-button size="small" type="primary" @click="sxjOnSubmit">提交</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["headerTit"]),
  },
  data() {
    return {
      sxjaddDialogVisible: false,
      addForm: {
        user_name: "",
        userpass: "",
        re_userpass: "",
      },
    };
  },
  created() { },
  methods: {
    sxjaddHandleClose() {
      this.sxjaddDialogVisible = false
    },
    async sxjOnSubmit() {
      const res = await this.$api.modify_userpass({
        ...this.addForm
      })
      this.$message({
        message: res.msg,
      });
      if (res.result == 1) {

        sessionStorage.setItem("token", null);
        sessionStorage.setItem("isLogin", null);
        setTimeout(()=>{
          this.$router.go(0);
        this.$router.push({ path: "/login" });
        },1000)
        
      }
    },
    handleCommand(command) {
      if (command == "2") {
        sessionStorage.setItem("token", null);
        sessionStorage.setItem("isLogin", null);
        this.$router.go(0);
        this.$router.push({ path: "/login" });
      } else if (command == '1') {
        this.sxjaddDialogVisible = true
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.header {
  display: flex;
  position: relative;
  background-color: #fff;

  .nav1 {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    font-size: 14px;

    .tit1 {
      color: #999;

      .active {
        color: #515a61;
        font-weight: 700;
      }
    }

    .tit2 {
      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }
}
</style>
